export const reformatAirtableData = (dataMapElement) => {
  // Change data format from Airtable to fit fuzzySearch
  let jsonFormat = {
    brand: dataMapElement?.brand?.[0],
    drugName: dataMapElement?.drugName?.[0],
    molecule: dataMapElement?.molecule?.[0],
    price_list: {
      name: dataMapElement?.priceList,
      vat: dataMapElement?.vat,
      vatex_unit_price: dataMapElement?.vatexUnitPrice,
    },
    productTitle: dataMapElement.productTitle?.[0],
    rxRequired: dataMapElement.rxRequired?.[0],
    drugRxLevel: dataMapElement.drugRxLevel?.[0],
    maxQuantity: dataMapElement.maxQuantity,
    classification: dataMapElement?.classification?.[0],
    description: dataMapElement?.description?.[0],
    category: dataMapElement?.category?.[0],
    skuTags: dataMapElement?.skuTags || [],
  }

  return jsonFormat
}
