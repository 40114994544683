import React from "react"

const SpecialRequestModal = ({
  route,
  handleContinueSearching,

  handleProceedToUpload,
}) => {
  let requestMessage = `${route} is selected. Please proceed to checkout and indicate your request
  on the Order Notes field.`
  if (route === "MedGrocer Wellness Patient")
    requestMessage =
      "Upload your prescription, use the coupon given by your doctor, and specify your medicines in the order notes."

  return (
    <p className="has-text-left">
      {requestMessage}
      <p className="mt-1">
        Example: <br />
        Metformin 500mg, 50 pieces
      </p>
      <div className="buttons is-centered mt-2">
        <button
          className="button is-primary is-outlined"
          onClick={handleContinueSearching}
        >
          Continue Searching
        </button>
        <button className="button is-primary" onClick={handleProceedToUpload}>
          Proceed to Upload
        </button>
      </div>
    </p>
  )
}

export default SpecialRequestModal
